<template>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-11"></div>
                    <div class="col-1 p-4">
                        <cc-button
                            :classes="'fixed'"
                            :icon="'fas fa-chevron-left'"
                            :content="'Voltar'"
                            :link="'/fornecedores'" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading" class="default" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th class="text-left pl-3">ID</th>
                                    <th>Responsável</th>
                                    <th>Email</th>
                                    <th>CNPJ</th>
                                    <th>Telefone</th>
                                    <th class="text-left">Status</th>
                                    <th class="text-left">Enviado em</th>
                                    <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="registration in registrations" v-bind:key="'head-office-' + registration.id" :class="{
                                    waiting: registration.status == 'P',
                                    confirmed: registration.status == 'C',
                                }">
                                    <td class="text-left">
                                        <strong>#{{ registration.id }}</strong>
                                    </td>
                                    <td class="text-uppercase">{{ registration.name }}</td>
                                    <td>{{ registration.email }}</td>
                                    <td class="text-uppercase">{{ registration.cnpj }}</td>
                                    <td>
                                        <a class="zap" :href="'https://api.whatsapp.com/send?phone=' + formatNumber(registration.phone)" target="_blank">
                                            <i class="fab fa-whatsapp"></i>
                                            {{ registration.phone || "" | VMask('(##) ####-#####') }}
                                        </a>
                                    </td>
                                    <td>
                                        <i class="far fa-envelope pr-2"></i>
                                        {{ translate_status(registration.status)  }}
                                    </td>
                                    <td>{{ registration.created_at | formatDate }}</td>
                                    <td class="actions">
                                        <i class="fa fa-trash" title="Suspender Registro" @click="() => suspend(registration)" />
                                        <i class="fa fa-user-plus" title="Efetivar Registro " @click="open_provider_modal(registration)" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="page"
                        :page_limit="11"
                        v-on:change="load($event)"></cc-paginacao>
                </div>
            </div>
        </div>
        <cc-new-provider-modal
            :registration="current_registration"
            @close="close_modal"
            v-if="is_provider_modal_open"
        />
    </div> -->

    <div class="registration-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">             
                Solicitações Fornecedor
            </div>
        </div>
        <div class="page-title">Solicitações Fornecedor</div>
        <div class="registration-page-button"><StandardButton :action="back" :iconName="'arrow_back'" :text="'Voltar'"/></div>
        <div class="page-table-header">
            <div class="page-table-header-text id-width">ID</div>
            <div class="page-table-header-text resp-width">RESPONSÁVEL</div>
            <div class="page-table-header-text email-width">EMAIL</div>
            <div class="page-table-header-text document-width">CNPJ</div>
            <div class="page-table-header-text phone-width">TELEFONE</div>
            <div class="page-table-header-text resale-width">REVENDA</div>
            <div class="page-table-header-text status-width">STATUS</div>
            <div class="page-table-header-text date-width">ENVIADO EM</div>
            <div class="action-width"></div>
        </div>
        <div class="page-table-line" :class="getSpecialBackground(index)" v-for="(registration, index) in registrations" v-bind:key="'head-office-' + registration.id">
            <div class="page-table-row id-width">
                <div class="page-table-header-mobile">ID</div>
                #{{ registration.id }}
            </div>
            <div class="page-table-row resp-width">
                <div class="page-table-header-mobile">RESPONSÁVEL</div>
                {{ registration.name }}
            </div>
            <div class="page-table-row email-width">
                <div class="page-table-header-mobile">EMAIL</div>
                {{ registration.email }}
            </div>
            <div class="page-table-row document-width">
                <div class="page-table-header-mobile">CNPJ</div>
                {{ registration.cnpj }}
            </div>
            <div class="page-table-row phone-width">
                <div class="page-table-header-mobile">TELEFONE</div>
                <a class="zap" :href="'https://api.whatsapp.com/send?phone=' + formatNumber(registration.phone)" target="_blank">
                    <i class="fab fa-whatsapp"></i>
                    {{ registration.phone || "" | VMask('(##) ####-#####') }}
                </a></div>
            <div class="page-table-row resale-width">
                <div class="page-table-header-mobile">REVENDA</div>
                {{ registration.resale ? registration.resale.name : '' }}
            </div>
            <div class="page-table-row status-width">
                <div class="page-table-header-mobile">STATUS</div>
                {{ registration.imported ? 'Importado' : 'Em aberto'  }}
            </div>
            <div class="page-table-row date-width">
                <div class="page-table-header-mobile">ENVIADO EM</div>
                {{ registration.created_at | formatDate }}
            </div>
            <div class="action-width">
                <div class="page-table-header-mobile"></div>
                <div style="display: flex;">
                   <span class="material-icons-outlined page-title-icon-del" title="Suspender Registro" @click="() => suspend(registration)">delete</span>
                    <span class="material-icons-outlined page-title-icon-add" title="Efetivar Registro " @click="open_provider_modal(registration)">group_add</span> 
                </div>
            </div>
        </div>
        
        <cc-loader-full v-if="is_loading"/>
        <cc-new-provider-modal
            :registration="current_registration"
            @close="close_modal"
            v-if="is_provider_modal_open"
        />
    </div>
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import ProviderService from "@/services/v3/provider.service";
import NewProviderModal from "@/modals/provider/new/new-provider.modal";
import StandardButton from '@/components/ui/buttons/StandardButton.vue'

export default {
    mixins: [ loaderMixin ],
    components: {
        ccPaginacao: Paginacao,
        'cc-new-provider-modal' : NewProviderModal,
        StandardButton
    },
    data(){
        return{
            is_loading: false,
            total_items: 1,
            page: 1,
            statuses: [
                {
                    name: 'Aguardando Confirmação',
                    code: 'WAITING_CONFIRMATION'
                },
                {
                    name: 'Confirmado',
                    code: 'CONFIRMED'
                },
                {
                    name: 'Cancelado',
                    code: 'CANCELED'
                }
            ],
            is_provider_modal_open: false,
            items_by_page_value: 25,
            svc: new ProviderService(),
            registrations: [],
            current_registration: null,
            breadcrumbs: [
                {
                    name: "Fornecedores"
                },
                {
                    name: "Requisição de Registros",
                    route: 'provider-registration-requests'
                }
            ]
        }
    },
    methods: {
        back () {
            this.$router.push('/fornecedores/')
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        close_modal(reload = false) {
            this.is_provider_modal_open = false
            if(reload) {
                this.load()
            }
        },
        open_provider_modal(registration) {
            this.current_registration = registration
            this.is_provider_modal_open = true
        },
        suspend(registration) {
            this.confirm_action({
				message: "Este registro será suspenso, confirma?",
                subtitle: `#${registration.name.toUpperCase()}`,
				callback: () => {
                    this.present_loader("Suspendendo registro...")
                    this.svc.suspend_registration(registration.id).then(() => {
                        this.present_info("Registro suspenso!")
                        this.load()
                    })
				}
			})
        },
        translate_status(status) {
            switch(status) {
                case "C":
                    return "Confirmado"
                case "P":
                    return "Pendente"
                default:
                    return "Não Reconhecido"
            }
        },
        load(new_page = this.page) {
            this.page = new_page;
            this.is_loading = true;
            this.svc.request_registrations()
            .then(response => response.data).then(data => {
                this.registrations = data.data;
                this.total_items = data.meta.total;
                this.is_loading = false;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
        this.load();
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .canceled {
        i {
            color: $brand-danger;
        }
    }
    .waiting {
        i.fa-envelope {
            color: $yellow2;
        }
    }
    .confirmed {
        i.fa-envelope {
            color: $ternary-color;
        }
    }

    .zap {
        i {
            color:#4AC959;
        }
        width: fit-content;
        font-size: 1em;
        &:hover {
            background: #4AC959;
            color: white;
            border-radius: 5px;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            margin-left: 10px;
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }

.monitors {
  font-size: 1vw;
  margin-right: 4em;
}
.registration-page {
    font-size: 1vw;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: #FF9954;
  margin-right: 20px;
}
.page-title{
  margin-top: 45px;
  font-weight: 700;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
}
.registration-page-button{
    margin-top: 5vh;
    display: flex;
    justify-content: flex-end;
    margin-right: 5vw;
}
.page-table-header-mobile{display: none;}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
  margin-top: 5vh;
  margin-right: 5vw;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.id-width {  width: 5%; }
.resp-width {  width: 13%; }
.email-width {  flex: 1; }
.document-width {  width: 11%; }
.phone-width {  width: 13%; }
.resale-width {  width: 10%; }
.status-width {  width: 10%; }
.date-width {  width: 11%; }
.action-width {  width: 7%; }
.page-table-line-special { background: #F7F7F7;}
.page-table-line{
    display: flex;
    margin-right: 5vw;
    align-items: center;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1em;
  color: #605F5F;
  padding: 2vh 0 2vh 10px;
}
.page-table-line-special { background: #F7F7F7;}
.page-title-icon-add{
    cursor: pointer;
    color: #4267B2;
}
.page-title-icon-del{
    cursor: pointer;
    color: red;
    margin-right: 10%;
}

@media only screen and (max-width: 800px) {
    .registration-page { font-size: 2vw; }
    .page-table-header{display: none;}
    .page-table-line{flex-direction: column;}
    .page-table-row{
        justify-content: space-between;
        width: 100%;
        flex: 1;
        font-size: 1.2em;
    }
    .action-width{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1vw;
    }
    .page-table-header-mobile{
        color: #605F5F;
        display: flex;
        font-weight: 400;
        font-size: 1.2em;
    }
}
@media only screen and (max-width: 450px){
    .registration-page { font-size: 3vw; }
}
</style>
